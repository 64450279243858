<template>
  <section>
    <h1>{{form.kysfirm.name}}</h1>
    <a-tabs
      v-model="activeTabKey"
      default-active-key="active"
    >
    <a-tab-pane key="active" tab="Active">
          <a-table size="small" style="margin-bottom:20px"
           :columns="kysfirmTable.columns"
           :data-source="kysfirms"
           :rowKey="'id'">
           <span slot="id" slot-scope="id, record" >
              <a v-if="$auth.user.profile.role == 'admin'" class="ant-dropdown-link"  @click="addPane(record, subscriber)">
                <span style="font-size:12px">{{ id }}</span>
              </a>
              <div v-else>{{ id }}</div>
           </span>
           <span slot="names" slot-scope="names">
             <div style="font-size:12px" v-for="name in names">{{ name }}</div>
           </span>
         </a-table>
    </a-tab-pane>

      <a-tab-pane key="link" tab="Search" v-if="$auth.user.profile.role == 'admin'" >

        <a-form layout="inline">
          <a-row>
            <a-col :span="16">
              <a-form-item label="Name/Id">
               <a-input style="width:250px" v-model="searchparams.name" placeholder="" />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item>
               <a-button size="small" type="primary" html-type="submit" @click="search('startswith')">
                 StartsWith
               </a-button>
             </a-form-item>
           </a-col>
           <a-col :span="4">
             <a-form-item>
               <a-button size="small"  type="primary" html-type="submit" @click="search('similar')">
                 Similar
               </a-button>
             </a-form-item>
           </a-col>
         </a-row>
        </a-form>
        <a-table size="small" style="font-size:12px;"
         :columns="kysfirmTable.columns"
         :data-source="kysfirm_search"
         :rowKey="'id'"
         :scroll="{ y: 340 }"
         >
         <span slot="id" slot-scope="id, record">
               <a class="ant-dropdown-link"  @click="addPane(record, subscriber)">
                 <span style="font-size:12px">{{ id }}</span>
               </a>
         </span>
         <span slot="names" slot-scope="names">
           <div style="font-size:12px" v-for="name in names">{{ name }}</div>
         </span>
       </a-table>

       </a-tab-pane>
      <a-tab-pane key="create" tab="create" v-if="$auth.user.profile.role == 'admin'" >
        <a-form :layout="form.layout">
           <a-form-item label="Type"
              :label-col="form.itemLayout.labelCol"
              :wrapper-col="form.itemLayout.wrapperCol"
           >
             <a-select default-value="Unknown" v-model="form.kysfirm.company_type">
               <a-select-option value="Unknown">
                 None
               </a-select-option>
               <a-select-option value="Registered">
                 Registered
               </a-select-option>
               <a-select-option value="Financial">
                 Financial
               </a-select-option>
               <a-select-option value="TradingInstitution">
                 Learning/Trading Institution
               </a-select-option>
               <a-select-option value="NonFinancial">
                 Non Financial
               </a-select-option>
             </a-select>
           </a-form-item>
           <a-form-item label="Scope"
              :label-col="form.itemLayout.labelCol"
              :wrapper-col="form.itemLayout.wrapperCol"
           >
             <a-select default-value="GLOBAL" v-model="form.kysfirm.scope">
               <a-select-option value="1">
                 None
               </a-select-option>
               <a-select-option value="SUBSCRIBER">
                 Subscriber - {{ subscriber.id }}
               </a-select-option>
               <a-select-option value="NAMELIST">
                 Namelist - {{ subscriber.namelist_name }}
               </a-select-option>
               <a-select-option value="COUNTRY">
                 Country - {{ subscriber.country }}
               </a-select-option>
               <a-select-option value="GLOBAL">
                 ALL / Global
               </a-select-option>
             </a-select>
           </a-form-item>
           <a-form-item label="Description"
             :label-col="form.itemLayout.labelCol"
             :wrapper-col="form.itemLayout.wrapperCol"
           >
             <a-textarea v-model="form.kysfirm.description" placeholder="" :rows="4" />
           </a-form-item>
           <a-form-item label="Link1"
             :label-col="form.itemLayout.labelCol"
             :wrapper-col="form.itemLayout.wrapperCol"
             >
            <a-input v-model="form.kysfirm.link1" placeholder="https://" />
           </a-form-item>
           <a-form-item label="Link2"
             :label-col="form.itemLayout.labelCol"
             :wrapper-col="form.itemLayout.wrapperCol"
             >
            <a-input v-model="form.kysfirm.link2" placeholder="https://" />
           </a-form-item>
           <a-form-item label="Link3"
             :label-col="form.itemLayout.labelCol"
             :wrapper-col="form.itemLayout.wrapperCol"
             >
            <a-input v-model="form.kysfirm.link3" placeholder="https://" />
           </a-form-item>
           <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
              <a-button type="primary" html-type="submit" @click="create">
                Submit
              </a-button>
            </a-form-item>
         </a-form>
      </a-tab-pane>
      <a-tab-pane v-for="pane in editpanes" :key="pane.key" :tab="pane.title" :closable="true">
        <KysFirmEditor :kysfirm="pane.kysfirm" :subscriber="pane.subscriber"/>
      </a-tab-pane>
    </a-tabs>



  </section>
</template>
<style>
  .strong {
    color:black;
    font-weight:500;
    font-size:13px;
  }

  .industry {
    font-style: italic;
  }
</style>
<script>

import KysFirmEditor from '@/components/kysfirm/KysFirmEditor.vue'
import subscriber from "@/components/Subscriber.vue";

export default {
  name: 'KysFirmPanel',
  components: {
    KysFirmEditor,
  },
  data: ()=>{
    return {
      mode: 'link',
      activeTabKey: 'active',
      kysfirms: [],
      kysfirm_search: [],
      linked_kysfirm_ids: [],
      kysfirm_map: {},
      searchparams: {
        id: '',
        name: ''
      },
      editpanes: [],
      kysfirmTable: {
        columns: [
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            scopedSlots: { customRender: 'id' },
          },
          {
            title: 'Names',
            dataIndex: 'names',
            key: 'names',
            scopedSlots: { customRender: 'names' },
          },
          {
            title: 'Type',
            dataIndex: 'company_type',
            key: 'company_type',
          },
          {
            title: 'Scope',
            dataIndex: 'scope_value',
            key: 'scope_value',
            scopedSlots: { customRender: 'scope' },
          }
        ]
      },
      form: {
        kysfirm: {
          name: null,
          company_type: null,
          scope: null,
          scope_value: null,
          description: null,
          link1: null,
          link2: null,
          link3: null
        },
        layout: 'horizontal',
        itemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        }
      },
      show_all: false
    }
  },
  props: {
    name: String,
    kysfirm: Object,
    subscriber: Object
  },
  watch: {
    'name': async function(newname, oldname) {
      console.log('watcher', newname, oldname)
      if (this.kysfirm) {
        this.form.kysfirm = Object.assign( {}, this.kysfirm )
        this.searchparams.name = this.kysfirm.name
      }

      if (this.name) {
        this.form.kysfirm = {name:this.name}
      }
      console.log(this.form)
      this.kysfirms = []
      this.linked_kysfirm_ids = []
      self.kysfirm_search = []
      this.get_linked()
    }
  },
  mounted: function () {
    console.log('mounted')
    if (this.kysfirm) {
      this.form.kysfirm = Object.assign( {}, this.kysfirm )
      this.searchparams.name = this.kysfirm.name
    }

    if (this.name) {
      this.form.kysfirm = {name:this.name}
    }
    console.log(this.form)
    this.kysfirms = []
    this.linked_kysfirm_ids = []
    self.kysfirm_search = []
    this.get_linked()
  },
  methods: {
    get_linked: async function () {

      const params = new URLSearchParams({name:this.form.kysfirm.name, matching:'EXACT'})

      let kysfirms = this.kysfirms
      kysfirms.length = 0

      this.linked_kysfirm_ids = []

      var self = this
      if(this.form.kysfirm.name && this.form.kysfirm.name.length > 2) {
        var kysfirm_response = await this.$kysapi.get('api/kysfirms?' + params.toString(), this.$auth)
        kysfirm_response.data.forEach((kysfirm, i) => {
          kysfirm.linked = true;
          self.linked_kysfirm_ids.push(kysfirm.id)
          kysfirms.push(kysfirm)
          const web_sources = kysfirm.web_sources
          web_sources.forEach((s, i) => {
            kysfirm['link' + (i + 1).toString()] = s.url;
          })
        })
      }
      console.log('KysFirmPanel', 'get_linked', self.kysfirms)
    },
    search: async function(matching) {
      var self = this
      self.kysfirm_search = []

      let query = new URLSearchParams({
        matching: matching
      })
      if (this.searchparams.id && this.searchparams.id.length > 0) {
        query.append('id', this.searchparams.id)
      }
      if (this.searchparams.name && this.searchparams.name.length > 0) {
        query.append('name', this.searchparams.name)
      }
      const url = 'api/kysfirms'  + "?" + query.toString()
      var response = await this.$kysapi.get(url, this.$auth)
      console.log( matching + ' search', response)
      response.data.forEach((kysfirm, i) => {
        self.kysfirm_search.push(kysfirm)
      })
    },
    addPane: async function(kysfirm, subscriber) {
      this.activeTabKey = 'kys'+kysfirm.id

      for(let i = 0; i < this.editpanes.length; i++) {
        if (this.editpanes[i].kysfirm.id === kysfirm.id) {
          return
        }
      }
      console.log('add pane=============')
      console.log(subscriber)
      this.editpanes.push({
        key: 'kys'+kysfirm.id,
        title: kysfirm.id,
        kysfirm: kysfirm,
        subscriber: subscriber,
      })
    },
    create: async function(){
      let kysfirm = Object.assign( {}, this.form.kysfirm )
      if(kysfirm.scope === 'COUNTRY'){
        kysfirm.scope_value = this.subscriber.country
      }
      else if(kysfirm.scope === 'NAMELIST'){
        kysfirm.scope_value = this.subscriber.namelist_name
      }
      else if(kysfirm.scope === 'SUBSCRIBER'){
        kysfirm.scope_value = this.subscriber.id
      }
      var response = await this.$kysapi.post('api/kysfirms', kysfirm, this.$auth)
      console.log('create kysfirm response', response)
      if(response.data.success) {
        this.$notification.success({
          message: 'KysFirm Created'
        });
        this.$emit('update')
      } else {
        this.$notification.error({
          message: response.data.message
        });
      }


    }
  }
}
</script>
