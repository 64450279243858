<template>

  <a-layout>
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible
                    style="padding-top:20px;margin-left:10px;width:200px;background:#fff"
    >
      <kys-sidebar></kys-sidebar>
    </a-layout-sider>
    <a-layout>
      <a-layout-content
          :style="{ padding: '24px', background: '#fff', minHeight: '280px' }"
      >
        <div>
          <h1>Namelists</h1>
          <a-card style="max-width:1080px">
            <a-row>
              <a-col span="3" style="margin-bottom:10px">
                <a-input allow-clear ref="tableFilterInput" v-model="tableFilter" placeholder="filter">
                  <a-icon slot="prefix" type="search"/>
                </a-input>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-spin size="large" :spinning="spinning" style="width:100%">
                  <a-table
                      :columns="namelistTableColumns"
                      :pagination="pagination"
                      :data-source="filteredNamelists"
                      :rowKey="'id'">
                    <a slot="name" slot-scope="text">{{ text }}</a>
                    <span slot="customTitle"><a-icon type="smile-o"/> Name</span>
                    <span slot="tags" slot-scope="tags">
                      <a-tag
                          v-for="tag in tags"
                          :key="tag"
                          :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
                      >
                        {{ tag.toUpperCase() }}
                      </a-tag>
                    </span>
                    <span slot="status" slot-scope="text, record">
                      {{ record.status }}
                    </span>
                    <span slot="subscribers" slot-scope="text, record">
                      {{ record.num_subscribers }}
                    </span>
                    <span slot="action" slot-scope="text, record">
                      <a-button @click="rerun(record)"
                                v-if="shouldShowRerun(record)"
                                style="margin-right: 5px;">Rerun</a-button>
                      <a-button @click="deleteNamelist(record)" v-if="false">Delete</a-button>
                    </span>
                  </a-table>
                </a-spin>
              </a-col>
            </a-row>
          </a-card>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<style>

.kys-h1 {
  padding-top: 20px;
}

.kys-card {
  max-width: 1080px;
}
</style>
<script>

import KysSidebar from "../components/HomeSidebar";

const fb = require('../firebaseConfig.js')

export default {
  name: 'NamelistManager',
  components: {KysSidebar},
  data() {
    return {
      user: fb.session.user,
      collapsed: false,
      view: 'namelists',
      namelists: [],
      namelist_result_limit: 10000,
      files: [],
      tableFilter: '',
      pagination: {
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20'],
      },
      spinning: false,
      namelistTableColumns: [
        {
          dataIndex: 'name',
          key: 'name',
          title: 'Name'
        },
        {
          title: 'Subscribers',
          scopedSlots: {customRender: 'subscribers'},
          key: 'process_stats.subscribers',
        },
        {
          dataIndex: 'when_entered_date',
          key: 'when_entered_date',
          title: 'Created'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status'
        },
        {
          title: 'Action',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        },
      ],
    }
  },
  computed: {
    filteredNamelists: function () {
      const self = this;
      return this.namelists.filter((item) => {
        if (!self.tableFilter || self.tableFilter.length < 2) {
          return true;
        }
        let filterText = self.tableFilter && self.tableFilter.toLowerCase();
        if (item.client && item.client.toLowerCase().includes(filterText)) {
          return true;
        }
        if (item.name && item.name.toLowerCase().includes(filterText)) {
          return true;
        }
        return false;
      })
    }
  },
  mounted: async function () {
    this.spinning = true;
    await this.getNamelists();
    this.spinning = false;
  },
  methods: {
    getNamelists: async function () {
      let response = await this.$kysapi.get('api/namelists?limit=' + this.namelist_result_limit, this.$auth)
      const {data} = response
      const {namelists} = this
      while (namelists.length > 0) {
        namelists.pop();
      }
      const existing = namelists.map((e) => {
        return e.id
      })
      data.namelists.forEach((namelist, i) => {
        if (namelist.status != 'ARCHIVED' && !existing.includes(namelist.id)) {
          namelists.push(namelist)
          this.files.push(...namelist.files)
        }
      });
    },
    shouldShowRerun: function (record) {
      return record.job_info && record.status !== 'RUNNING' && record.status !== 'PENDING';
    },
    rerun: async function (record) {
      this.spinning = true;
      await this.triggerNamelistRerun(record);
      await this.getNamelists();
      this.spinning = false;
    },
    triggerNamelistRerun: async function (record) {
      let job_info = record.job_info;
      if (!job_info) {
        alert('Failed to rerun namelist');
        return;
      }
      try {
        await this.$kysapi.post('api/rerunnamelist', {
          uid: record.uid
        }, this.$auth)
      } catch (e) {
        alert('Failed to rerun namelist')
      }
    },
    deleteNamelist: async function (record) {
      if (confirm("Please confirm you want to delete this namelist") === true) {
        this.spinning = true;
        await this.triggerNamelistDelete(record);
        await this.getNamelists();
        this.spinning = false;
      }
    },
    triggerNamelistDelete: async function (record) {
      try {
        await this.$kysapi.post('api/namelists/' + record.name, {
          id: record.id,
          status: 'DELETED'
        }, this.$auth)
      } catch (e) {
        alert('Failed to delete namelist')
      }
    },
  }
}
</script>
