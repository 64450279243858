<template>
  <div class="home">

    <a-layout>
        <a-layout-sider v-model="collapsed" :trigger="null" collapsible
          style="padding-top:20px;margin-left:10px;width:200px;background:#fff"
        >
          <kys-sidebar ></kys-sidebar>
        </a-layout-sider>
        <a-layout>
          <a-layout-content
            :style="{ padding: '24px', background: '#fff', minHeight: '280px' }"
          >
          <div v-show="view == 'namelists'">
            <h1>Upload File</h1>
            <a-card style="max-width:1080px">
              <a-row>
                <a-col>

  <a-upload-dragger
    name="upload_file"
    :multiple="true"
    action="https://kysapi.prosparency.com/api/files"
    :headers="headers"
    @change="getFiles"
    :showUploadList="false"
    >
    <p class="ant-upload-drag-icon">
      <a-icon type="plus" />
    </p>
    <p class="ant-upload-text">
      Click here or drag file to this area to upload
    </p>
    <p class="ant-upload-hint">
      CSV, XLSX only
    </p>
  </a-upload-dragger>

                </a-col>
              </a-row>
              </a-card>
          </div>
          <div style="padding-top:30px" >
            <h1>Recent Files</h1>
            <a-card style="max-width:1080px">
              <a-spin size="large" :spinning="spinning" style="width:100%">
                <a-table :columns="columns" :data-source="files">
                  <a slot="name"  slot-scope="text, record" @click="download_file(record)" >{{ text }}</a>
                  <span slot="modified" slot-scope="text, record">
                    {{ fromNow(text) }}
                  </span>
                </a-table>
              </a-spin>
            </a-card>
          </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
  </div>
</template>

<script>
import KysSidebar from "../components/HomeSidebar";
import moment from 'moment'

const fb = require('../firebaseConfig.js')
const kysapi = require('../kysapi')
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
    key: 'modified',
    scopedSlots: { customRender: 'modified' },
  },
];

export default {
  name: 'Home',
  components: {
    KysSidebar
  },
  data() {
    return {
      user: fb.session.user,
      collapsed: false,
      columns: columns,
      view: 'namelists',
      namelists: [],
      namelist_result_limit: 5,
      files: [],
      spinning: false,
      namelistTableColumns: [
        {
          dataIndex: 'name',
          key: 'name',
          title:'Name'
        },
        {
          title: 'Subscribers',
          scopedSlots: { customRender: 'subscribers' },
          key: 'process_stats.subscribers',
        },
        {
          title: 'Action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
        {
          dataIndex: 'when_entered_date',
          key: 'when_entered_date',
          title:'Created'
        }
      ],
      headers: {
          Authorization: `Bearer ${this.$auth.accessToken}`
        }
    }
  },
  mounted: async function () {
    this.spinning = true;
    this.getFiles();
    this.spinning = false;
  },
  methods: {
    getFiles: async function() {
      
      var response = await this.$kysapi.get('api/files', this.$auth)

      let files = response.data.files

      this.files.splice(0, this.files.length)

      files.forEach((file, i) => {
        file.key = file.Key
        this.files.push(file)
      })
      this.files.sort((a,b) =>  moment(b['LastModified']).valueOf() -  moment(a['LastModified']).valueOf())

    },
    download_file: async function(file){

      console.log(file)
      console.log('api/file/' + file.Key)
      const response = await this.$kysapi.get('api/file/' + file.Key, this.$auth, {responseType: "blob"})
      console.log(response)
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    fromNow (t) {
      return moment(t).fromNow()
    },
  }
}
</script>
